import React from 'react'
import { string, number, shape, objectOf, oneOfType, array, any } from 'prop-types'
import { expandState } from '@helpers/geo-location'
import { getStoreSlug, createStoreDetailPath } from '@helpers/store-locator'
import RTGLink from '@components/shared/link'
import { useStaticQuery, graphql } from 'gatsby'

const StoreName = ({ mark: msStoreData }) => {
  const strapiStoreData = useStaticQuery(query)

  // Sync up strapi and microservice data to reconcile link url between PDP See in Store and Store Locator
  const strapiStore = strapiStoreData?.allStrapiStore?.edges?.find(
    e => e.node.StoreNumber === Number(msStoreData.storeNumber),
  )?.node

  if (!strapiStore) {
    return (
      <span className="storeName" role="heading" aria-level="3">
        {msStoreData.name}
      </span>
    )
  }

  const link = createStoreDetailPath({ ...strapiStore })
  return (
    <span className="storeName" role="heading" aria-level="3">
      <RTGLink
        data={{
          url: getStoreSlug(strapiStore),
          title: `Change Location to ${msStoreData.name} Store`,
          category: 'product-lia',
          action: 'change location click',
          label: msStoreData.name,
        }}
      >
        {msStoreData.name}
      </RTGLink>
    </span>
  )
}

StoreName.propTypes = {
  mark: shape({
    city: string,
    hours: oneOfType([shape({ regularHours: array }), array]),
    lat: number,
    lng: number,
    name: string,
    phoneNumber: string,
    state: string,
    storeNumber: number,
    title: string,
    zip: string,
  }).isRequired,
}

export default StoreName

const query = graphql`
  query StoresQuery {
    allStrapiStore {
      edges {
        node {
          id
          StoreNumber
          StoreName
          StoreType
          State
          City
          SpecialClosings {
            Markdown {
              Markdown {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
      }
    }
  }
`
