import React, { useState } from 'react'
import { string, number, shape, objectOf, oneOfType, array, any } from 'prop-types'
import classNames from 'classnames'
import { getStandardTime, weekdays } from '@helpers/string-helper'
import { showDay } from './helpers'

const StoreHours = ({ mark, currentDay, tomorrowDay, specialClosings }) => {
  const [showHours, toggleShowHours] = useState(false)
  let hours

  if (mark.hours && !mark.hours.regularHours) {
    hours = mark.hours
  } else if (mark.hours && mark.hours.regularHours) {
    hours = mark.hours.regularHours
  }

  return (
    <>
      <div className="hours">
        {specialClosings && (
          <span
            className="special-closings"
            dangerouslySetInnerHTML={{
              __html: specialClosings?.Markdown?.Markdown?.childMarkdownRemark?.html,
            }}
          />
        )}
        <div className="store-hours-wrapper">
          <div className="heading">Store Hours</div>
        </div>
        {hours &&
          hours.map((hour, i) => {
            if (!showHours) {
              return showDay(hour, i, currentDay, tomorrowDay)
            }
            return (
              <div
                className={classNames('hour', {
                  hidden: !showHours,
                })}
                key={hour.day ? hour.day : weekdays[hour.dayIndex]}
              >
                <span className="day">{hour.day ? hour.day : weekdays[hour.dayIndex]}</span>:{' '}
                {hour.openTime && hour.closeTime
                  ? `${getStandardTime(hour.openTime)} - ${getStandardTime(hour.closeTime)}`
                  : 'Closed'}
              </div>
            )
          })}
      </div>
      <div>
        <button
          type="button"
          className="fake-link showAllBtn"
          value="Show All"
          onClick={() => toggleShowHours(!showHours)}
        >
          {!showHours ? 'Show All' : 'Show Less'}
        </button>
      </div>
    </>
  )
}

StoreHours.propTypes = {
  mark: shape({
    city: string,
    hours: oneOfType([shape({ regularHours: array }), array]),
    lat: number,
    lng: number,
    name: string,
    phoneNumber: string,
    state: string,
    storeNumber: number,
    title: string,
    zip: string,
  }).isRequired,
  currentDay: string,
  tomorrowDay: string,
  specialClosings: shape({
    markdown: objectOf(any),
  }),
}

export default StoreHours
