import React, { useState } from 'react'
import { arrayOf, object, func, string, bool } from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { letters } from '@helpers/string-helper'
import '../../assets/css/components/store-locator/map-locations-list.sass'
import InStoreScheduleModal from '@shared/modals/InStoreSchedule'
import { isMobile } from 'react-device-detect'
import Rating from '@material-ui/lab/Rating'
import Box from '@material-ui/core/Box'
import StorePhone from './store-phone'
import StoreAddress from './store-address'
import StoreHours from './store-hours'
import StoreName from './store-name'
import StoreRating from './store-rating'
import { getSpecialClosings } from './helpers'

const getTotalRatings = lifetimeRatings => {
  const { count1star, count2Stars, count3Stars, count4Stars, count5Stars } = lifetimeRatings
  const totalRatings =
    Number(count1star) + Number(count2Stars) + Number(count3Stars) + Number(count4Stars) + Number(count5Stars)
  return totalRatings
}

const MapLocationList = ({ markers, onLocationClick, currentDay, tomorrowDay, lia }) => {
  const data = useStaticQuery(query)
  const [scheduleModalData, setScheduleModalData] = useState({ show: false, store: null })

  return (
    <div className="locations">
      {markers.map((mark, index) => {
        const phone = mark.phone || mark.phoneNumber
        const specialClosings = getSpecialClosings({ data, mark })
        const selectedRatings = mark?.ratings?.lifetime
        return (
          <div
            role={isMobile ? null : 'button'}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={isMobile ? null : '0'}
            className="locationRow"
            onClick={isMobile ? null : () => onLocationClick(index, mark)}
            onKeyDown={
              isMobile
                ? null
                : e => {
                    if (e.keyCode === 13) onLocationClick(index, mark)
                  }
            }
            key={`${mark.title}|${mark.name}`}
          >
            <div className="imgCol">
              <img
                src={`https://maps.google.com/mapfiles/marker${letters[index]}.png`}
                role={isMobile ? 'presentation' : null}
                aria-hidden={isMobile}
                alt={isMobile ? '' : 'Map Marker'}
              />
            </div>
            <div className="addrCol">
              <Box display="flex" alignItems="center">
                <StoreName mark={mark} lia={lia} />
                {selectedRatings?.overallScore && (
                  <StoreRating
                    ratingValue={selectedRatings?.overallScore}
                    totalRatings={getTotalRatings(selectedRatings)}
                  />
                )}
              </Box>
              <Box mt="5px" mb="15px">
                <StorePhone mark={mark} phone={phone} />
              </Box>
              <StoreAddress mark={mark} setScheduleModalData={setScheduleModalData} data={data} />
              <StoreHours
                mark={mark}
                currentDay={currentDay}
                tomorrowDay={tomorrowDay}
                specialClosings={specialClosings}
              />
            </div>
          </div>
        )
      })}

      {scheduleModalData.store && scheduleModalData.show && (
        <InStoreScheduleModal
          shouldShowModal={scheduleModalData.show}
          store={{
            ...scheduleModalData.store,
            storeNumber: scheduleModalData.store.StoreNumber || scheduleModalData.store.storeNumber,
            StoreNumber: scheduleModalData.store.StoreNumber || scheduleModalData.store.storeNumber,
          }}
          closeModal={() => setScheduleModalData({ show: false, store: null })}
        />
      )}
    </div>
  )
}

MapLocationList.propTypes = {
  markers: arrayOf(object),
  onLocationClick: func,
  currentDay: string,
  tomorrowDay: string,
  lia: string,
}

const query = graphql`
  query StoresClosingsQuery {
    allStrapiStore {
      edges {
        node {
          id
          InStoreAppointments
          StoreNumber
          SpecialClosings {
            Markdown {
              Markdown {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
      }
    }
  }
`

export default MapLocationList
