import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import Rating from '@material-ui/lab/Rating'

const StoreRating = ({ ratingValue, totalRatings }) => (
  <Box display="flex" alignItems="center" ml="10px">
    <Rating readOnly value={ratingValue} precision={0.1} />
    <Box mx="5px" spacing={1} fontWeight={700}>
      {ratingValue}
    </Box>
  </Box>
)

StoreRating.propTypes = {
  ratingValue: PropTypes.number,
  totalRatings: PropTypes.number,
}

export default StoreRating
