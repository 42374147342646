import React from 'react'
import { string, number, shape, func, objectOf, oneOfType, array, any } from 'prop-types'
import RTGLink from '@components/shared/link'
import { getRegionZone } from '@helpers/geo-location'
import { getAcceptingSAS } from './helpers'

const regionInPR = getRegionZone().region === 'PR'

const StoreAddress = ({ mark, setScheduleModalData, data }) => {
  const isAcceptingSAS = getAcceptingSAS({ data, mark })
  const onOpenModal = location => {
    window.dataLayer.push({ event: 'schedule_visit_start', storeId: location.storeNumber })
    setScheduleModalData({ show: true, store: location })
  }
  return (
    <div className="address">
      <RTGLink
        data={{
          url: `https://www.google.com/maps/dir/?api=1&origin=Current+Location&destination=${mark.lat},${mark.lng}`,
          title: `Directions to ${mark.name}`,
          category: 'store locator',
          action: 'Directions store map click',
          label: 'Directions to store',
        }}
        tabIndex="0"
        target="_blank"
        className="store-address-link"
      >
        {mark.title}
        <br />
        {mark.city}, {mark.state} {mark.zip}
        <span className="hide508">(opens in new window)</span>
      </RTGLink>
      <br />
      {mark.distance && <>{mark.distance} miles</>}
      {!regionInPR && !mark.name.toLowerCase().includes('outlet') && isAcceptingSAS && (
        <>
          <br />
          <span
            role="button"
            tabIndex="0"
            className="small-12 bookStoreApt"
            onClick={() => onOpenModal(mark)}
            onKeyDown={e => {
              if (e.keyCode === 13) onOpenModal(mark)
            }}
          >
            Schedule a Showroom Visit
          </span>
        </>
      )}
    </div>
  )
}

StoreAddress.propTypes = {
  setScheduleModalData: func,
  mark: shape({
    city: string,
    hours: oneOfType([shape({ regularHours: array }), array]),
    lat: number,
    lng: number,
    name: string,
    phoneNumber: string,
    state: string,
    storeNumber: number,
    title: string,
    zip: string,
  }).isRequired,
  data: objectOf(any),
}

export default StoreAddress
