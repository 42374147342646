import React from 'react'
import { string, number, shape, array, oneOfType } from 'prop-types'
import RTGLink from '@components/shared/link'

const StorePhone = ({ phone, mark }) => (
  <div className="phone">
    <RTGLink
      data={{
        url: `tel:${phone.replace('/', '-')}`,
        title: `Call ${mark.name} Store`,
        category: 'store-locator',
        action: 'call store click',
        label: mark.name,
      }}
      tabIndex="0"
    >
      {phone.replace('/', '-')}
    </RTGLink>
  </div>
)

StorePhone.propTypes = {
  phone: string,
  mark: shape({
    city: string,
    hours: oneOfType([shape({ regularHours: array }), array]),
    lat: number,
    lng: number,
    name: string,
    phoneNumber: string,
    state: string,
    storeNumber: number,
    title: string,
    zip: string,
  }).isRequired,
}

export default StorePhone
