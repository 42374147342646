import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import { keyboardBlur } from '@helpers/input-helper'

const onEnterPress = (e, changeZip) => {
  if (e.keyCode === 13 && e.shiftKey === false) {
    changeZip()
    keyboardBlur(e)
  }
}

const SearchBar = ({ invalidZip, zipInput, updateInputZipValue, changeZip }) => (
  <div className="storeLocator__wrapper -leftColumn -search">
    <label
      className={classNames({
        invalid: invalidZip,
      })}
      htmlFor="location"
    >
      {invalidZip && <p className="invalid-message">*Please enter a valid ZIP code</p>}
      <span className="storeLocator__wrapper -leftColumn -search -wrapper">
        <input
          type="text"
          className="storeLocator__wrapper -leftColumn -search -input"
          name="location"
          id="location"
          placeholder="Enter a ZIP code"
          aria-label="Enter a ZIP code to search for stores"
          value={zipInput}
          onChange={evt => updateInputZipValue(evt)}
          onKeyDown={e => onEnterPress(e, changeZip)}
        />
        <button
          type="button"
          value="Change zip code"
          name="change_zip"
          className="storeLocator__wrapper -leftColumn -search -button"
          id="submit-btn"
          aria-label="Submit"
          gtm-category="see in store"
          gtm-action="change zip code"
          onClick={changeZip}
        >
          Search
        </button>
      </span>
    </label>
  </div>
)

SearchBar.propTypes = {
  changeZip: PropTypes.any,
  invalidZip: PropTypes.any,
  updateInputZipValue: PropTypes.func,
  zipInput: PropTypes.any,
}

export default SearchBar
